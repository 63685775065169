import React from 'react';
import './ShoppingBanner.css';

const CricketTraining = () => {
    return (
        <div className='shopping'>
            <img src="https://i.ibb.co/BsDTsys/banner-IMG.png" alt="Shopping" />
        </div>
    );
};

export default CricketTraining;