import React from "react";


const UpdateNews = () => {
 
  return (
    <div>
        <div className="my-orders btn btn--block btn--radius btn--size-xlarge btn--color-white btn--bg-maya-blue text-center contact-btn">
        <h1>Sorry! Now Update News Here</h1>
      </div>
       
    </div>
  );
};

export default UpdateNews;
