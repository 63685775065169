import React from 'react';
import './CricketTraining.css';
const CricketTraining = () => {
    return (
        <div className='training'>
            <img src="https://i.ibb.co/V9bkhYd/training-video.png" alt="cricket" />
        </div>
    );
};

export default CricketTraining;